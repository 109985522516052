import imp from '@/lib/imp';
import { createInjectionComponent, UvRoute, UvRouteHeader } from '@/lib/router';
import actions from '@/pages/assets/modules/products/actions/routes';
import create from '@/pages/assets/modules/products/create/routes';
import _import from '@/pages/assets/modules/products/import/routes';
import view from '@/pages/assets/modules/products/view/routes';

const header: UvRouteHeader = {
  label: 'Produse',
  route: 'assets.products.index',
  menu: (_, { downloadList }) => [
    {
      label: 'Adauga produs',
      icon: 'add',
      separated: true,
      route: {
        name: 'assets.products.create',
      },
      permissions: ['create.products'],
    },
    {
      label: 'Importa produse din inventar',
      icon: 'drive_file_move',
      route: {
        name: 'assets.products.import.inventory',
      },
      permissions: ['create.products'],
    },
    {
      label: 'Importa produse din fisier',
      icon: 'upload',
      route: {
        name: 'assets.products.import.file',
      },
      permissions: ['create.products'],
    },
    {
      label: 'Importa campuri dinamice de produs din fisier',
      icon: 'upload',
      route: {
        name: 'assets.products.import.dynamic-fields',
      },
      permissions: ['update.products'],
    },
    {
      label: 'Descarca lista produse',
      icon: 'cloud_download',
      click: downloadList,
    },
  ],
};

const route: UvRoute = {
  path: 'products',
  props: true,
  component: createInjectionComponent(),
  meta: {
    breadcrumb: {
      label: 'Produse',
      route: 'assets.products.index',
    },
    header,
  },
  children: [
    {
      path: '',
      name: 'assets.products.index',
      props: true,
      meta: {
        permissions: ['read.products'],
      },
      component: imp(
        () => import('@/pages/assets/modules/products/List.vue' /* webpackChunkName: 'assets.products.main' */),
      ),
      children: actions,
    },
    create,
    _import,
    view,
  ],
};

export default route;
