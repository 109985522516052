import { Api, Endpoint } from '@/lib/api-builder';

export interface EndpointCollection {
  req: Endpoint;

  [k: string]: EndpointCollection | Endpoint;
}

export default (api: Api) => ({
  auth: {
    login: api.post('/auth/login', { cookies: true }),
    logout: api.post('/auth/logout', { cookies: true }),
    refresh: api.post('/auth/refresh', { cookies: true }),
    permissions: api.get('/auth/permissions'),
  },
  access: {
    token: api.post('/access'),
  },
  ancpi: {
    search: api.get('/ancpi/search'),
  },
  assets: {
    osm: {
      jwt: api.post('/assets/osm/jwt'),
    },
    maintenances: {
      index: api.get('/assets/modules/:moduleId/maintenances'),
      show: api.get('/assets/modules/:moduleId/maintenances/:maintenanceId'),
      create: api.post('/assets/modules/:moduleId/maintenances'),
      archive: api.post('/assets/modules/:moduleId/maintenances/:maintenanceId/archive'),
      edit: {
        name: api.put('/assets/modules/:moduleId/maintenances/:maintenanceId/edit/name'),
      },
    },
    notifications: {
      index: api.get('/assets/modules/:moduleId/notifications'),
      create: api.post('/assets/modules/:moduleId/notifications'),
      show: api.get('/assets/modules/:moduleId/notifications/:notificationConfigId'),
      delete: api.delete('/assets/modules/:moduleId/notifications/:notificationConfigId'),
    },
    flows: {
      index: api.get('/assets/modules/:moduleId/flows'),
      show: api.get('/assets/modules/:moduleId/flows/:flowId'),
      create: api.post('/assets/modules/:moduleId/flows'),
      activate: api.post('/assets/modules/:moduleId/flows/:flowId/activate'),
      edit: {
        name: api.put('/assets/modules/:moduleId/flows/:flowId/edit/name'),
      },
      delete: api.delete('/assets/modules/:moduleId/flows/:flowId'),
      checklist: {
        add: api.post('/assets/modules/:moduleId/flows/:flowId/items'),
        show: api.get('/assets/modules/:moduleId/flows/:flowId/items/:itemId'),
        rename: api.put('/assets/modules/:moduleId/flows/:flowId/items/:itemId/rename'),
        delete: api.delete('/assets/modules/:moduleId/flows/:flowId/items/:itemId'),
        reorder: api.put('/assets/modules/:moduleId/flows/:flowId/items/reorder'),
      },
    },
    fields: {
      category: api.get('/assets/modules/:moduleId/fields/category/:categoryId'),
    },
    products: {
      index: api.get('/assets/modules/:moduleId/products'),
      create: api.post('/assets/modules/:moduleId/products'),
      show: api.get('/assets/modules/:moduleId/products/:productId'),
      deleteSelected: api.delete('/assets/modules/:moduleId/products/selected'),
      deleteAll: api.delete('/assets/modules/:moduleId/products/all'),
      deleteSelectedDynamicFields: api.delete('/assets/modules/:moduleId/products/selected/dynamic-fields'),
      deleteAllDynamicFields: api.delete('/assets/modules/:moduleId/products/all/dynamic-fields'),
      import: {
        inventory: {
          items: api.get('/assets/modules/:moduleId/products/import/inventory/:inventoryId/scriptic/items'),
          status: api.get('/assets/modules/:moduleId/products/import/inventory/:inventoryId/status'),
          import: api.post('/assets/modules/:moduleId/products/import/inventory/:inventoryId/import'),
        },
        file: {
          heading: api.get('/assets/modules/:moduleId/products/import/file/heading'),
          items: api.get('/assets/modules/:moduleId/products/import/file/items'),
          upload: api.post('/assets/modules/:moduleId/products/import/file/upload', { asJSON: false }),
          status: api.get('/assets/modules/:moduleId/products/import/file/status'),
          import: api.post('/assets/modules/:moduleId/products/import/file/import'),
          errors: api.get('/assets/modules/:moduleId/products/import/file/errors'),
        },
        dynamicFields: {
          heading: api.get('/assets/modules/:moduleId/products/import/dynamic-fields/heading'),
          import: api.post('/assets/modules/:moduleId/products/import/dynamic-fields/import', { asJSON: false }),
          errors: api.get('/assets/modules/:moduleId/products/import/dynamic-fields/errors'),
        },
      },
      edit: {
        labels: api.put('/assets/modules/:moduleId/products/:productId/labels'),
        management: api.put('/assets/modules/:moduleId/products/:productId/management'),
        dates: api.put('/assets/modules/:moduleId/products/:productId/dates'),
        stocks: api.put('/assets/modules/:moduleId/products/:productId/stocks'),
        location: api.put('/assets/modules/:moduleId/products/:productId/location'),
        supplier: api.put('/assets/modules/:moduleId/products/:productId/supplier'),
        warranty: api.put('/assets/modules/:moduleId/products/:productId/warranty'),
        photos: {
          upload: api.post('/assets/modules/:moduleId/products/:productId/photos', { asJSON: false }),
          delete: api.delete('/assets/modules/:moduleId/products/:productId/photos/:photoId'),
        },
        files: {
          upload: api.post('/assets/modules/:moduleId/products/:productId/files', { asJSON: false }),
          download: api.get('/assets/modules/:moduleId/products/:productId/files/:fileId'),
        },
        fields: api.put('/assets/modules/:moduleId/products/:productId/fields'),
      },
      flows: {
        index: api.get('/assets/modules/:moduleId/products/:productId/flows'),
        create: api.post('/assets/modules/:moduleId/products/:productId/flows'),
        show: api.get('/assets/modules/:moduleId/products/:productId/flows/:flowId'),
        edit: {
          description: api.patch('/assets/modules/:moduleId/products/:productId/flows/:flowId/description'),
        },
        items: {
          check: api.patch('/assets/modules/:moduleId/products/:productId/flows/:flowId/items/:itemId'),
        },
      },
      reports: {
        maintenances: {
          expiring: api.get('/assets/modules/:moduleId/products/reports/maintenances/expiring'),
          pending: api.get('/assets/modules/:moduleId/products/reports/maintenances/pending'),
          progressing: api.get('/assets/modules/:moduleId/products/reports/maintenances/progressing'),
        },
        general: api.get('/assets/modules/:moduleId/products/reports/general'),
        dynamic: {
          index: api.get('/assets/modules/:moduleId/products/reports/dynamic'),
          query: api.get('/assets/modules/:moduleId/products/reports/dynamic/:reportId'),
        },
      },
      maintenances: {
        index: api.get('/assets/modules/:moduleId/products/:productId/maintenances'),
        attach: api.post('/assets/modules/:moduleId/products/:productId/maintenances'),
        findByFlow: api.post('/assets/modules/:moduleId/products/:productId/maintenances/find-by-flow'),
        show: api.get('/assets/modules/:moduleId/products/:productId/maintenances/:maintenanceId'),
        start: api.post('/assets/modules/:moduleId/products/:productId/maintenances/:maintenanceId/start'),
        archive: api.post('/assets/modules/:moduleId/products/:productId/maintenances/:maintenanceId/archive'),
        delete: api.delete('/assets/modules/:moduleId/products/:productId/maintenances/:maintenanceId'),
      },
      inventory: {
        items: {
          index: api.get('/assets/modules/:moduleId/products/:productId/inventory/items'),
          create: api.post('/assets/modules/:moduleId/products/:productId/inventory/items'),
          show: api.get('/assets/modules/:moduleId/products/:productId/inventory/items/:itemId'),
          edit: api.put('/assets/modules/:moduleId/products/:productId/inventory/items/:itemId'),
          delete: api.delete('/assets/modules/:moduleId/products/:productId/inventory/items/:itemId'),
        },
        map: {
          index: api.get('/assets/modules/:moduleId/products/:productId/inventory/map/areas/:areaId/items'),
          assign: api.post('/assets/modules/:moduleId/products/:productId/inventory/map/areas/:areaId/items/:itemId'),
          unassign: api.delete(
            '/assets/modules/:moduleId/products/:productId/inventory/map/areas/:areaId/items/:itemId',
          ),
          quantity: api.put('/assets/modules/:moduleId/products/:productId/inventory/map/areas/:areaId/quantity'),
        },
      },
      download: api.get('/assets/modules/:moduleId/products/download'),
    },
    services: {
      index: api.get('/assets/modules/:moduleId/services'),
      list: api.get('/assets/modules/:moduleId/services/list'),
      create: api.post('/assets/modules/:moduleId/services'),
      show: api.get('/assets/modules/:moduleId/services/:serviceId'),
      deleteSelected: api.delete('/assets/modules/:moduleId/services/selected'),
      deleteAll: api.delete('/assets/modules/:moduleId/services/all'),
      edit: {
        index: api.patch('/assets/modules/:moduleId/services/:serviceId'),
        contract: api.patch('/assets/modules/:moduleId/services/:serviceId/edit/contract'),
        startDate: api.patch('/assets/modules/:moduleId/services/:serviceId/edit/start-date'),
        receptionDate: api.patch('/assets/modules/:moduleId/services/:serviceId/edit/reception-date'),
        warranty: api.patch('/assets/modules/:moduleId/services/:serviceId/edit/warranty'),
        supplier: api.patch('/assets/modules/:moduleId/services/:serviceId/edit/supplier'),
        photos: {
          upload: api.post('/assets/modules/:moduleId/services/:serviceId/photos', { asJSON: false }),
        },
        files: {
          upload: api.post('/assets/modules/:moduleId/services/:serviceId/files', { asJSON: false }),
          download: api.get('/assets/modules/:moduleId/services/:serviceId/files/:fileId'),
        },
      },
      products: {
        index: api.get('/assets/modules/:moduleId/services/:serviceId/products'),
        create: api.post('/assets/modules/:moduleId/services/:serviceId/products/:productId'),
        delete: api.delete('/assets/modules/:moduleId/services/:serviceId/products/:productId'),
      },
      location: api.put('/assets/modules/:moduleId/services/:serviceId/location'),
      reports: {
        detailed: api.get('/assets/modules/:moduleId/services/reports/detailed'),
        centralized: api.get('/assets/modules/:moduleId/services/reports/centralized'),
      },
    },
    modules: {
      index: api.get('/assets/modules'),
      show: api.get('/assets/modules/:moduleId'),
      products: api.get('/assets/modules/:moduleId/products'),
      services: api.get('/assets/modules/:moduleId/services'),
      map: {
        area: {
          products: {
            inventory: {
              items: {
                index: api.get('/assets/modules/:moduleId/map/area/:areaId/products/:productId/inventory/items'),
              },
            },
          },
        },
      },
    },
  },
  urbanism: {
    regulations: {
      index: api.get('/urbanism/regulations'),
      create: api.post('/urbanism/regulations'),
      show: api.get('/urbanism/regulations/:regulationId'),
      edit: api.put('/urbanism/regulations/:regulationId'),
      files: {
        index: api.get('/urbanism/regulations/:regulationId/files'),
        upload: api.post('/urbanism/regulations/:regulationId/files', { asJSON: false }),
        download: api.get('/urbanism/regulations/:regulationId/files/:fileId'),
      },
      areas: {
        index: api.get('/urbanism/regulations/areas'),
        information: api.post('/urbanism/regulations/areas/information'),
      },
      area: {
        show: api.get('/urbanism/regulations/:regulationId/area'),
        bounds: api.get('/urbanism/regulations/:regulationId/area/bounds'),
        edit: api.put('/urbanism/regulations/:regulationId/area'),
      },
      subdivisions: {
        index: api.get('/urbanism/regulations/:regulationId/subdivisions'),
        create: api.post('/urbanism/regulations/:regulationId/subdivisions'),
        show: api.get('/urbanism/regulations/:regulationId/subdivisions/:subdivisionId'),
        edit: api.put('/urbanism/regulations/:regulationId/subdivisions/:subdivisionId'),
        neighbors: api.get('/urbanism/regulations/:regulationId/subdivisions/:subdivisionId/neighbors'),
        files: {
          index: api.get('/urbanism/regulations/:regulationId/subdivisions/:subdivisionId/files'),
          upload: api.post('/urbanism/regulations/:regulationId/subdivisions/:subdivisionId/files', { asJSON: false }),
          download: api.get('/urbanism/regulations/:regulationId/subdivisions/:subdivisionId/files/:fileId'),
        },
        areas: api.get('/urbanism/regulations/:regulationId/subdivisions/areas'),
        area: {
          show: api.get('/urbanism/regulations/:regulationId/subdivisions/:subdivisionId/area'),
          bounds: api.get('/urbanism/regulations/:regulationId/subdivisions/:subdivisionId/area/bounds'),
          edit: api.put('/urbanism/regulations/:regulationId/subdivisions/:subdivisionId/area'),
        },
      },
    },
    subdivisionTypes: {
      index: api.get('/urbanism/subdivision-types'),
      create: api.post('/urbanism/subdivision-types'),
      show: api.get('/urbanism/subdivision-types/:subdivisionTypeId'),
      edit: api.put('/urbanism/subdivision-types/:subdivisionTypeId'),
      files: {
        index: api.get('/urbanism/subdivision-types/:subdivisionTypeId/files'),
        upload: api.post('/urbanism/subdivision-types/:subdivisionTypeId/files', { asJSON: false }),
        download: api.get('/urbanism/subdivision-types/:subdivisionTypeId/files/:fileId'),
      },
    },
  },
  institution: {
    reports: {
      userActivity: api.get('/institution/reports/user-activity/:userId'),
    },
    show: api.get('/institution'),
    users: {
      index: api.get('/institution/users'),
      create: api.post('/institution/users'),
      show: api.get('/institution/users/:userId'),
      edit: {
        name: api.patch('/institution/users/:userId/name'),
        email: api.patch('/institution/users/:userId/email'),
        password: api.patch('/institution/users/:userId/password'),
        roles: api.patch('/institution/users/:userId/roles'),
        suspend: api.patch('/institution/users/:userId/suspend'),
        unsuspend: api.patch('/institution/users/:userId/unsuspend'),
      },
    },
    roles: {
      index: api.get('/institution/roles'),
      builder: api.get('/institution/roles/builder'),
      loaders: api.get('/institution/roles/loaders/:conditionId'),
    },
    suppliers: {
      index: api.get('/institution/suppliers'),
      create: api.post('/institution/suppliers'),
      show: api.get('/institution/suppliers/:supplierId'),
      edit: api.put('/institution/suppliers/:supplierId'),
      activate: api.post('/institution/suppliers/activate'),
    },
    tileProviders: {
      index: api.get('/institution/tile-providers'),
    },
  },
  incidents: {
    index: api.get('/incidents'),
    create: api.post('/incidents'),
    show: api.get('/incidents/:incidentId'),
    resolve: api.post('/incidents/:incidentId/resolve'),
    categories: {
      index: api.get('/incidents/categories'),
      create: api.post('/incidents/categories'),
      icons: api.get('/incidents/categories/icons'),
      show: api.get('/incidents/categories/:categoryId'),
      edit: api.put('/incidents/categories/:categoryId'),
    },
    citizenReports: {
      index: api.get('/incidents/citizen-reports'),
      show: api.get('/incidents/citizen-reports/:citizenReportId'),
      reject: api.post('/incidents/citizen-reports/:citizenReportId/reject'),
      generate: api.post('/incidents/citizen-reports/generate'),
      relatedIncidents: {
        index: api.get('/incidents/citizen-reports/:citizenReportId/related-incidents'),
        assign: api.post('/incidents/citizen-reports/:citizenReportId/related-incidents'),
      },
    },
    information: {
      show: api.get('/incidents/information'),
      edit: api.put('/incidents/information'),
    },
  },
  inventory: {
    index: api.get('/inventory'),
    active: api.get('/inventory/active'),
    stats: api.get('/inventory/active/stats'),
    create: api.post('/inventory'),
    next: api.post('/inventory/next'),
    show: api.get('/inventory/:inventoryId'),
    edit: api.post('/inventory/:inventoryId'),
    reset: api.post('/inventory/active/reset'),
    import: {
      confirmed: api.get('/inventory/active/import/:source/confirmed'),
      print: api.get('/inventory/active/import/:source/print/:format'),
      codes: api.post('/inventory/active/import/:source/codes', { acceptJSON: false }),
      upload: api.post('/inventory/active/import/:source/:type/upload', { asJSON: false }),
      errors: api.get('/inventory/active/import/:source/:type/errors'),
      stats: api.get('/inventory/active/import/:source/:type/stats'),
      searchScriptic: api.get('/inventory/active/import/:source/:type/search-scriptic'),
      confirm: api.post('/inventory/active/import/:source/:type/confirm'),
      reset: api.post('/inventory/active/import/:source/:type/reset'),
      items: {
        index: api.get('/inventory/active/import/:source/:type/items'),
        create: api.post('/inventory/active/import/:source/:type/items'),
        show: api.get('/inventory/active/import/:source/:type/items/:itemId'),
        edit: api.put('/inventory/active/import/:source/:type/items/:itemId'),
        delete: api.delete('/inventory/active/import/:source/:type/items/:itemId'),
        quantities: {
          create: api.post('/inventory/active/import/:source/:type/items/:itemId/quantities'),
          edit: api.patch('/inventory/active/import/:source/:type/items/:itemId/quantities/:quantityId'),
          delete: api.delete('/inventory/active/import/:source/:type/items/:itemId/quantities/:quantityId'),
        },
      },
      initial: {
        markAsQty: api.post('/inventory/active/import/:source/:type/items/:itemId/mark-as-qty'),
      },
      receptions: {
        importDates: api.get('/inventory/active/import/reception/import-dates'),
        markAsQty: api.post('/inventory/active/import/:source/:type/items/:itemId/mark-as-qty'),
        reset: api.post('/inventory/active/import/:source/:type/reset'),
      },
      quashes: {
        candidates: api.get('/inventory/active/import/quash/:type/items/:itemId/candidates'),
        distributions: api.patch('/inventory/active/import/quash/:type/items/:itemId/distributions'),
        reset: api.post('/inventory/active/import/:source/:type/reset'),
      },
    },
    orgChart: {
      create: api.post('/inventory/:inventoryId/org-chart'),
      nodes: {
        index: api.get('/inventory/:inventoryId/org-chart/nodes'),
        tree: api.get('/inventory/:inventoryId/org-chart/nodes/tree'),
        add: api.post('/inventory/:inventoryId/org-chart/nodes'),
        rename: api.patch('/inventory/:inventoryId/org-chart/nodes/:nodeId/rename'),
        move: api.patch('/inventory/:inventoryId/org-chart/nodes/:nodeId/move'),
        delete: api.delete('/inventory/:inventoryId/org-chart/nodes/:nodeId'),
      },
    },
    reports: {
      lists: api.get('/inventory/:inventoryId/reports/lists/:reportType'),
      scannedLists: api.get('/inventory/:inventoryId/reports/scanned-lists/:type'),
      user: api.get('/inventory/:inventoryId/reports/user'),
    },
    printTemplates: {
      index: api.get('/inventory/print-templates'),
      create: api.post('/inventory/print-templates'),
      show: api.get('/inventory/print-templates/:templateId'),
      preview: api.get('/inventory/print-templates/preview'),
      edit: api.put('/inventory/print-templates/:templateId'),
    },
    scriptic: {
      heading: api.get('/inventory/:inventoryId/scriptic/heading'),
      locations: api.get('/inventory/:inventoryId/scriptic/locations'),
      items: {
        index: api.get('/inventory/:inventoryId/scriptic/items'),
        print: api.get('/inventory/:inventoryId/scriptic/items/print/:format'),
        scanSelected: api.post('/inventory/:inventoryId/scriptic/items/scan-selected'),
        scanAll: api.post('/inventory/:inventoryId/scriptic/items/scan-all'),
        create: api.post('/inventory/:inventoryId/scriptic/items'),
        show: api.get('/inventory/:inventoryId/scriptic/items/:itemId'),
        edit: api.put('/inventory/:inventoryId/scriptic/items/:itemId'),
        delete: api.delete('/inventory/:inventoryId/scriptic/items/:itemId'),
        photos: {
          upload: api.post('/inventory/:inventoryId/scriptic/items/:itemId/photos', { asJSON: false }),
        },
        scans: {
          allocate: api.post('/inventory/:inventoryId/scriptic/items/:itemId/scans/allocate'),
          delete: api.delete('/inventory/:inventoryId/scriptic/items/:itemId/scans/:scanId'),
        },
        quantities: {
          create: api.post('/inventory/:inventoryId/scriptic/items/:itemId/quantities'),
          edit: api.patch('/inventory/:inventoryId/scriptic/items/:itemId/quantities/:quantityId'),
        },
      },
      codes: {
        print: api.post('/inventory/:inventoryId/scriptic/codes', { acceptJSON: false }),
        generate: api.get('/inventory/:inventoryId/scriptic/codes/generate', { acceptJSON: false }),
      },
    },
    committee: {
      index: api.get('/inventory/:inventoryId/committee'),
      create: api.post('/inventory/:inventoryId/committee'),
      show: api.get('/inventory/:inventoryId/committee/:committeeId'),
      edit: api.put('/inventory/:inventoryId/committee/:committeeId'),
      delete: api.delete('/inventory/:inventoryId/committee/:committeeId'),
    },
    scan: {
      find: api.post('/inventory/:inventoryId/scan/find'),
      confirm: api.post('/inventory/:inventoryId/scan/confirm'),
    },
    history: {
      scriptic: {
        index: api.get('/inventory/:inventoryId/history/scriptic/items'),
        show: api.get('/inventory/:inventoryId/history/scriptic/items/:itemId'),
      },
      receptions: {
        index: api.get('/inventory/:inventoryId/history/receptions/items'),
        show: api.get('/inventory/:inventoryId/history/receptions/items/:itemId'),
      },
      quashes: {
        index: api.get('/inventory/:inventoryId/history/quashes/items'),
        show: api.get('/inventory/:inventoryId/history/quashes/items/:itemId'),
      },
    },
  },
  counties: {
    index: api.get('/counties'),
    find: api.post('/counties/find'),
    localities: {
      index: api.get('/counties/:code/localities'),
    },
  },
  maps: {
    streets: {
      index: api.get('/maps/streets'),
      create: api.post('/maps/streets'),
      features: api.get('/maps/streets/features'),
      show: api.get('/maps/streets/:streetId'),
      updateDetails: api.patch('/maps/streets/:streetId'),
      updateFeatures: api.put('/maps/streets/:streetId/features'),
    },
  },
  me: {
    notifications: {
      list: api.get('/me/notifications'),
      count: api.get('/me/notifications/count'),
      read: api.post('/me/notifications/read'),
      registerToken: api.get('/me/notifications/register/:token'),
    },
    apiTokens: {
      list: api.get('/me/api-tokens'),
      create: api.post('/me/api-tokens'),
      delete: api.delete('/me/api-tokens/:apiToken'),
    },
  },
  admin: {
    settings: {
      index: api.get('/admin/settings'),
      update: api.put('/admin/settings'),
      updateIcons: api.post('/admin/settings/update-icons'),
    },
    applications: {
      index: api.get('/admin/applications'),
    },
    logs: {
      index: api.get('/admin/logs'),
    },
    users: {
      index: api.get('/admin/users'),
      create: api.post('/admin/users'),
      show: api.get('/admin/users/:userId'),
      edit: {
        name: api.patch('/admin/users/:userId/name'),
        email: api.patch('/admin/users/:userId/email'),
        password: api.patch('/admin/users/:userId/password'),
        roles: api.patch('/admin/users/:userId/roles'),
      },
      roles: api.get('/admin/users/:userId/available-roles'),
      institution: {
        save: api.patch('/admin/users/:userId/institution'),
      },
    },
    roles: {
      index: api.get('/admin/roles'),
      institution: api.get('/admin/roles/:institutionId'),
      loaders: api.get('/admin/roles/:institutionId/loaders/:conditionId'),
      builder: api.get('/admin/roles/builder'),
      create: api.post('/admin/roles'),
      show: api.get('/admin/roles/:roleId'),
      rename: api.patch('/admin/roles/:roleId/rename'),
      update: api.put('/admin/roles'),
      resources: api.get('/admin/roles/resources'),
    },
    institutions: {
      index: api.get('/admin/institutions'),
      create: api.post('/admin/institutions'),
      show: api.get('/admin/institutions/:institutionId'),
      edit: api.put('/admin/institutions/:institutionId'),
      suspensionDate: {
        save: api.patch('/admin/institutions/:institutionId/suspension-date'),
      },
      address: {
        save: api.patch('/admin/institutions/:institutionId/address'),
      },
      limits: {
        save: api.patch('/admin/institutions/:institutionId/limits'),
      },
      applications: {
        index: api.get('/admin/institutions/:institutionId/applications'),
        assign: api.post('/admin/institutions/:institutionId/applications/:applicationId'),
        unassign: api.delete('/admin/institutions/:institutionId/applications/:applicationId'),
      },
      modules: {
        index: api.get('/admin/institutions/:institutionId/assets/modules'),
        assign: api.post('/admin/institutions/:institutionId/assets/modules/:moduleId'),
        unassign: api.delete('/admin/institutions/:institutionId/assets/modules/:moduleId'),
      },
      inventory: {
        show: api.get('/admin/institutions/:institutionId/inventory'),
        delete: api.delete('/admin/institutions/:institutionId/inventory'),
        import: {
          enable: api.patch('/admin/institutions/:institutionId/inventory/:inventoryId/import/:type/enable'),
          disable: api.patch('/admin/institutions/:institutionId/inventory/:inventoryId/import/:type/disable'),
        },
      },
      tileProviders: {
        index: api.get('/admin/institutions/:institutionId/tile-providers'),
        create: api.post('/admin/institutions/:institutionId/tile-providers'),
        edit: api.put('/admin/institutions/:institutionId/tile-providers/:tileProviderId'),
        delete: api.delete('/admin/institutions/:institutionId/tile-providers/:tileProviderId'),
      },
    },
    modules: {
      index: api.get('admin/modules'),
      add: api.post('admin/modules'),
      show: api.get('admin/modules/:treeId'),
      rename: api.patch('admin/modules/:treeId/rename'),
      nodes: {
        index: api.get('admin/modules/:treeId/nodes'),
        tree: api.get('admin/modules/:treeId/nodes/tree'),
        add: api.post('admin/modules/:treeId/nodes'),
        rename: api.patch('admin/modules/:treeId/nodes/:nodeId/rename'),
        move: api.patch('admin/modules/:treeId/nodes/:nodeId/move'),
        delete: api.delete('admin/modules/:treeId/nodes/:nodeId'),
        icon: api.patch('/admin/modules/:treeId/nodes/:nodeId/icon'),
        fieldGroups: {
          index: api.get('admin/modules/:treeId/nodes/:nodeId/field-groups'),
          associate: api.post('admin/modules/:treeId/nodes/:nodeId/field-groups/associate'),
        },
      },
    },
    seed: {
      index: api.get('/admin/seed'),
    },
    migrate: {
      index: api.get('/admin/migrate'),
    },
    fields: {
      index: api.get('admin/fields'),
      create: api.post('/admin/fields'),
      show: api.get('admin/fields/:fieldId'),
      update: api.put('admin/fields/:fieldId'),
      values: {
        update: api.put('admin/fields/:fieldId/values'),
      },
    },
    fieldGroups: {
      index: api.get('admin/field-groups'),
      create: api.post('/admin/field-groups'),
      show: api.get('admin/field-groups/:fieldGroupId'),
      update: api.patch('admin/field-groups/:fieldGroupId'),
      fields: {
        create: api.post('admin/field-groups/:fieldGroupId/fields'),
        update: api.patch('admin/field-groups/:fieldGroupId/fields/:fieldId'),
        // values: {
        //   update: api.put('admin/field-groups/:fieldGroupId/fields/:fieldId/values')
        // }
      },
    },
  },
});
